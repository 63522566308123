import {
  TwitterIcon,
  TwitterShareButton,
  FacebookShareButton,
  FacebookIcon
} from "react-share";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;

  & [role="button"] {
    cursor: pointer;
  }
`;

export default ({ url, title }) => {
  return (
    <Wrapper>
      <TwitterShareButton url={url} title={title} via="galstar">
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      <FacebookShareButton url={url}>
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
    </Wrapper>
  );
};
