import Layout from "../components/Layout";
import React from "react";
import { Link, graphql } from "gatsby";
import "prismjs/themes/prism-tomorrow.css";
import styled, { css } from "styled-components";
import Helmet from "react-helmet";
import { RED, BRIGHT_RED } from "../components/PostDesign";
import Share from "../components/Share";

const BlogPostInfo = styled.div`
  direction: ${(p) => p.lang === "he" && "rtl"};

  & > h1 {
    font-family: "Assistant", sans-serif;
    color: ${RED};
  }

  & a {
    color: inherit;
    text-decoration-color: ${BRIGHT_RED};

    &:hover {
      text-decoration-color: ${RED};
    }
  }

  & > h2 {
    font-family: "Assistant", sans-serif;
    font-weight: normal;
    text-align: justify;
    font-size: 1.2em;
    margin-top: -1em;
    color: hsl(0, 5%, 40%);
  }

  & > h6 {
    font-family: "Assistant", sans-serif;
    font-weight: normal;
    font-size: 0.9em;
    margin-top: -1.2em;
    text-align: right;
    color: hsl(0, 5%, 60%);
  }
`;

const BlogPostContents = styled.article`
  direction: ${(p) => p.lang === "he" && "rtl"};

  font-family: "David Libre", serif;
  line-height: 1.6;
  font-size: 1.2em;
  font-weight: 400;

  .gatsby-highlight-code-line {
    display: block;
    transition: opacity 0.2s ease;
    opacity: 0.5;
  }

  div.gatsby-highlight + *,
  .twitter-tweet + p {
    margin-top: 1em;
  }

  .twitter-tweet {
    margin: auto;
  }

  & *:not([class*="language-"]) > code {
    display: inline-block;
    color: ${RED} !important;
    background-color: hsl(0, 30%, 97%) !important;
    padding: 0.5em !important;
    text-decoration: inherit;
    vertical-align: middle;
    font-size: 0.7em;
    margin: -0.5em 0;
  }

  & a {
    text-decoration-color: ${BRIGHT_RED};
    color: inherit;
    transition: text-decoration-color 0.2s ease;
    background-color: hsl(0, 30%, 97%);

    &:hover {
      text-decoration-color: ${RED};
    }

    &.anchor {
      background-color: transparent;
    }
  }

  & p > img {
    display: block;
    margin: auto;
  }

  & h1,
  & h2,
  & h3 {
    font-family: "Assistant";
    position: relative;

    &::before {
      position: absolute;
      color: ${BRIGHT_RED};
      ${(p) =>
        p.lang === "he"
          ? css`
              right: 0;
              transform: translate(120%) scale(0.8);
            `
          : css`
              left: 0;
              transform: translate(-120%) scale(0.8);
            `}
    }
  }

  & h1::before {
    content: "# ";
  }

  & h2::before {
    content: "## ";
  }

  & h3::before {
    content: "### ";
  }

  & blockquote {
    background-color: hsl(0, 30%, 97%) !important;
    padding: 1em;
    transform: skew(-5deg);
  }

  & strong {
    font-weight: 700;
  }

  & p {
    text-align: justify;
  }
`;

const Thanks = styled.section`
  font-family: Assistant, serif;
  font-size: 1em;
  text-align: right;
`;

const joinNames = (names) => {
  if (names.length === 1) return names[0];
  const namesButLast = names.slice(0, names.length - 1);
  const lastName = names[names.length - 1];
  return `${namesButLast.join(", ")} & ${lastName}`;
};

export default ({ data }) => {
  const post = data.markdownRemark;
  const {
    originallyPosted,
    title,
    subtitle,
    date,
    lang,
    thanks,
    keywords,
  } = post.frontmatter;
  const image =
    post.frontmatter.image && post.frontmatter.image.childImageSharp.original;
  const fluidImage =
    post.frontmatter.image && post.frontmatter.image.childImageSharp.fluid;
  const description =
    subtitle || (lang === "he" ? post.hebrewExcerpt : post.excerpt);
  const jsonld = {
    "@context": "http://schema.org",
    "@type": "BlogPosting",
    headline: title,
    alternativeHeadline: subtitle,
    image: image && data.site.siteMetadata.siteUrl + image.src,
    wordcount: post.wordCount.words,
    keywords: keywords,
    datePublished: date,
    dateCreated: date,
    dateModified: date,
    description,
    publisher: {
      "@type": "Organization",
      name: "Gal Schlezinger",
      logo:
        "https://www.gravatar.com/avatar/08e5c5456c6496c2054285ba53510bb2?s=500",
    },
    url: data.site.siteMetadata.siteUrl + post.fields.slug,
    articleBody: post.plainText,
    author: {
      "@type": "Person",
      name: data.site.siteMetadata.author,
      image:
        "https://www.gravatar.com/avatar/08e5c5456c6496c2054285ba53510bb2?s=500",
    },
  };

  const externalUrl = data.site.siteMetadata.siteUrl + post.fields.slug;

  return (
    <Layout image={image} fluidImage={fluidImage}>
      <Helmet title={title}>
        <meta property="og:title" content={title} />
        {image && (
          <meta
            property="og:image"
            content={data.site.siteMetadata.siteUrl + image.src}
          />
        )}
        <meta property="og:description" content={description} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
      </Helmet>
      <BlogPostInfo className="blog-post-info" lang={lang}>
        <h1>
          <Link to={post.fields.slug}>{title}</Link>
        </h1>
        {subtitle && <h2>{subtitle}</h2>}
        <h6>
          {!originallyPosted ? (
            <>Published at {date}</>
          ) : (
            <>
              Originally posted on{" "}
              <a href={originallyPosted.url}>{originallyPosted.on}</a> at {date}
            </>
          )}
        </h6>
      </BlogPostInfo>
      <BlogPostContents
        lang={lang}
        className="blog-post"
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
      {thanks && thanks.length > 0 && (
        <Thanks>Many thanks to {joinNames(thanks)}.</Thanks>
      )}
      <Share url={externalUrl} title={title} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      hebrewExcerpt: excerpt(truncate: true)
      html
      plainText
      wordCount {
        words
      }
      fields {
        slug
      }
      parent {
        ... on File {
          publicURL
        }
      }
      frontmatter {
        lang
        title
        subtitle
        thanks
        keywords
        date(formatString: "MMMM DD, YYYY")
        originallyPosted {
          on
          url
        }
        image {
          childImageSharp {
            original {
              src
              height
              width
            }
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
